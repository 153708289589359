import { Link } from '@remix-run/react'
import { Logo } from '#app/components/logo.tsx'
import { Button } from '#app/components/ui/button.tsx'
import UserDropdown from '#app/components/user-dropdown.tsx'
import { ThemeSwitch } from '#app/routes/resources+/theme-switch.tsx'
import { type Theme } from '#app/utils/theme.server.ts'

interface TopBarProps extends React.HTMLAttributes<HTMLElement> {
	theme: Theme | null
	searchBar: React.ReactNode
	hasUser: boolean
}

export default function TopBar({ theme, searchBar, hasUser }: TopBarProps) {
	return (
		<>
			<div className="sticky top-0 z-50 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 dark:border-border">
				<div className="flex h-14 items-center gap-4 px-4">
					<div className="flex items-center gap-2">
						<Logo />
						<ThemeSwitch userPreference={theme} />
					</div>

					<div className="ml-auto hidden max-w-sm flex-1 sm:block">
						{searchBar}
					</div>

					<div className="flex items-center gap-10">
						{hasUser ? (
							<UserDropdown />
						) : (
							<Button asChild variant="default" size="lg">
								<Link to="/login">Log In</Link>
							</Button>
						)}
					</div>
					<div className="block w-full sm:hidden">{searchBar}</div>
				</div>
			</div>
		</>
	)
}
