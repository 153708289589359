import { Icon } from '#app/components/ui/icon.tsx'
import { type SideLink } from '#app/src/shared/ui/web/sidebar/data/sidelinks.tsx'
import Sidebar from '#app/src/shared/ui/web/sidebar/sidebar.tsx'
import TopBar from '#app/src/shared/ui/web/topbar/topbar.tsx'
import { type Theme } from '#app/utils/theme.server.ts'

export const sidelinks: SideLink[] = [
	{
		title: 'Dashboard',
		label: '',
		href: '/dashboard',
		icon: <Icon name={'home'} />,
	},
	{
		title: 'Users',
		label: '',
		href: '/dashboard/users',
		icon: <Icon name={'person'} />,
	},
	{
		title: 'Bus',
		label: '',
		href: '/dashboard/bus',
		icon: <Icon name={'check'} />,
	},
	{
		title: 'Leagues',
		label: '',
		href: '/manager/league',
		icon: <Icon name={'person'} />,
	},
	{
		title: 'Teams',
		label: '',
		href: '/manager/team',
		icon: <Icon name={'check'} />,
	},
]

type NavigationProps = {
	sidebarEnabled: boolean
	theme: Theme | null
	hasUser: boolean
	searchBar: React.ReactNode
	isCollapsed: boolean
	setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}
const Navigation = ({
	sidebarEnabled,
	theme,
	hasUser,
	searchBar,
	isCollapsed,
	setIsCollapsed,
}: NavigationProps) => {
	if (sidebarEnabled) {
		return (
			<Sidebar
				isCollapsed={isCollapsed}
				setIsCollapsed={setIsCollapsed}
				theme={theme}
				sideLinks={sidelinks}
				hasUser={hasUser}
			/>
		)
	}

	return <TopBar theme={theme} searchBar={searchBar} hasUser={hasUser} />
}

export { Navigation }
