import { useEffect, useState } from 'react'
import { Button } from '#app/components/ui/button.tsx'
import { Icon } from '#app/components/ui/icon.tsx'
import { ThemeSwitch } from '#app/routes/resources+/theme-switch.tsx'
import { Layout } from '#app/src/shared/ui/web/sidebar/components/layout.tsx'
import Nav from '#app/src/shared/ui/web/sidebar/components/nav.tsx'
import { type SideLink } from '#app/src/shared/ui/web/sidebar/data/sidelinks.tsx'
import { cn } from '#app/utils/misc.tsx'
import { type Theme } from '#app/utils/theme.server.ts'
import { Logo } from '#app/components/logo.tsx'
import UserDropdown from '#app/components/user-dropdown.tsx'
import { Link } from '@remix-run/react'

interface SidebarProps extends React.HTMLAttributes<HTMLElement> {
	isCollapsed: boolean
	setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
	theme: Theme | null
	sideLinks: SideLink[]
	hasUser: boolean
}

export default function Sidebar({
	className,
	isCollapsed,
	setIsCollapsed,
	theme,
	sideLinks,
	hasUser,
}: SidebarProps) {
	const [navOpened, setNavOpened] = useState(false)

	/* Make body not scrollable when navBar is opened */
	useEffect(() => {
		if (navOpened) {
			document.body.classList.add('overflow-hidden')
		} else {
			document.body.classList.remove('overflow-hidden')
		}
	}, [navOpened])

	return (
		<aside
			className={cn(
				`fixed left-0 right-0 top-0 z-50 w-full border-r-2 border-r-muted transition-[width] md:bottom-0 md:right-auto md:h-svh ${isCollapsed ? 'md:w-14' : 'md:w-64'}`,
				className,
			)}
		>
			{/* Overlay in mobile */}
			<div
				onClick={() => setNavOpened(false)}
				className={`absolute inset-0 transition-[opacity] delay-100 duration-700 ${navOpened ? 'h-svh opacity-50' : 'h-0 opacity-0'} w-full bg-black md:hidden`}
			/>

			<Layout fixed className={navOpened ? 'h-svh' : ''}>
				{/* Header */}
				<Layout.Header
					sticky
					className="z-50 flex justify-between px-4 py-3 shadow-sm md:px-4"
				>
					<div className={`flex items-center ${!isCollapsed ? 'gap-2' : ''}`}>
						<Logo
							className={`transition-all ${isCollapsed ? 'h-6 w-6' : 'h-8 w-8'}`}
						/>
					</div>

					<div className="flex gap-2">
						<div
							className={
								'inline-flex items-center justify-center rounded-md text-sm font-medium outline-none ring-ring ring-offset-2 ring-offset-background transition-colors focus-within:ring-2 focus-visible:ring-2 disabled:pointer-events-none disabled:opacity-50'
							}
						>
							<ThemeSwitch userPreference={theme} />
						</div>
						{/* Toggle Button in mobile */}
						<Button
							variant="ghost"
							size="icon"
							aria-label="Toggle Navigation"
							aria-controls="sidebar-menu"
							className={'md:hidden'}
							aria-expanded={navOpened}
							onClick={() => setNavOpened((prev) => !prev)}
						>
							{navOpened ? (
								<Icon name={'cross-1'} />
							) : (
								<Icon name={'hamburger-menu'} />
							)}
						</Button>
					</div>
				</Layout.Header>

				{/* Navigation links */}
				<Nav
					id="sidebar-menu"
					className={`z-40 h-full flex-1 overflow-auto ${navOpened ? 'max-h-screen' : 'max-h-0 py-0 md:max-h-screen md:py-2'}`}
					closeNav={() => setNavOpened(false)}
					isCollapsed={isCollapsed}
					links={sideLinks}
				/>

				{/* Scrollbar width toggle button */}
				<Button
					onClick={() => setIsCollapsed((prev) => !prev)}
					size="icon"
					variant="outline"
					className="absolute -right-5 top-1/2 z-50 hidden rounded-full md:inline-flex"
				>
					<Icon
						name={'chevron-left'}
						className={`h-5 w-5 ${isCollapsed ? 'rotate-180' : ''}`}
					/>
				</Button>

				<div>
					<div className="flex items-center gap-10">
						{hasUser ? (
							<UserDropdown />
						) : (
							<Button asChild variant="default" size="lg">
								<Link to="/login">Log In</Link>
							</Button>
						)}
					</div>
				</div>
			</Layout>
		</aside>
	)
}
